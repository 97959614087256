import React from 'react';
import { useNavigate } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import '../styles/CourseCard.css'; // Import the CSS for this component

function CourseCard({ course }) {
  const navigate = useNavigate();
  const { id, course_number, title, subtitle, image, date, progress } = course;

  const cardStyle = {
    cursor: 'pointer', // Makes the cursor a pointer for the entire card
    // Include other styles for the card if necessary
  };

  const dateStyle = {
    color: '#fff', // Makes the date text white
    // Include other styles for the date if necessary
  };

  const titleStyle = {
    marginTop: '0px',
    fontWeight: 'bold', // Makes the date text bold
    // Include other styles for the date if necessary
  };

  return (
    <div style={cardStyle} onClick={() => navigate(`/blog-page/${id}`)}>
      <Card className="course-card">
        <div className="card-img" style={{ backgroundImage: `url(${image})` }}></div>
        <Card.Body>
          <Card.Subtitle className="mb-2" style={dateStyle}>{date}</Card.Subtitle>
          <h4 style={titleStyle}>{title}</h4>
          <p>{subtitle}</p>
          {/* You can add more elements like a link or button here */}
        </Card.Body>
      </Card>
    </div>
  );
}

export default CourseCard;
