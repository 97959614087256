import React from 'react';

function LottieComponent() {
  return (
    <div className="lottie-container2"> {/* justifyContent moved here */}
      <div className="lottie-content2"> {/* justifyContent added here */}
      <div class="upper">
        <lottie-player 
          src="https://lottie.host/a2ed0254-2816-406c-be43-77ef86ee6ae5/NZH0LEDBz5.json" 
          background="transparent" 
          speed="1" 
          loop 
          autoplay>
        </lottie-player>
        </div>
        <div className="lottie-text2">
        <h1>Simplified Four-Year Planning</h1>
          <p>
          Our platform simplifies four-year planning for students, parents, and administrators. Students can easily identify the courses needed to achieve any academic goal or pathway, whether it’s dual enrollment, high school graduation, or satisfying A-G criteria.
          </p>
          </div>
          <div class="lower">
          
        
          <lottie-player 
            src="https://lottie.host/a2ed0254-2816-406c-be43-77ef86ee6ae5/NZH0LEDBz5.json" 
            background="transparent" 
            speed="1" 
            loop 
            autoplay>
          </lottie-player>
          </div>
        
      </div>
    </div>
  );
}

export default LottieComponent;
