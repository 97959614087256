import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DndContext, closestCenter, DragOverlay } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import Callout from '../Callout';
import DroppableColumn from './DroppableColumn';
import SortableCourse from './SortableCourse';
import CourseCard from './CourseCard2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTh, faList, faDownload } from '@fortawesome/free-solid-svg-icons';
import loadingGif from '../../assets/images/animation.gif';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

const FourYearPlan = () => {
  const [isLoading, setIsLoading] = useState(true); 

  const [courses, setCourses] = useState({});
  const [activeId, setActiveId] = useState(null);
  const [view, setView] = useState('grid'); // Default view set to 'grid'
  const [errorMessage, setErrorMessage] = useState(''); // State to manage error messages
  const termOrder = { 'Spring': 1, 'Summer': 2, 'Fall': 3 };
  const [studentName, setStudentName] = useState('');

  const fixedColumns = ['9th Grade', '10th Grade', '11th Grade', '12th Grade'];

  const token = localStorage.getItem('token');
  const headers = {
    Authorization: `Token ${token}`,
  };
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

  useEffect(() => {
    const fetchScheduledCourses = async () => {
      try {
        const response = await axios.get(`${API_ENDPOINT}/api/user-scheduled-courses/`, { headers });
        const fetchedCourses = response.data;
        console.log('Fetched Courses:', fetchedCourses);

        const sortedCourses = fetchedCourses.sort(sortCoursesByAcademicTerm);

        const coursesByTerm = sortedCourses.reduce((acc, course) => {
          const { term } = course;
          if (!acc[term]) {
            acc[term] = [];
          }
          acc[term].push(course);
          return acc;
        }, {});

        console.log('Courses by Term:', coursesByTerm);
        setCourses(coursesByTerm);
        setIsLoading(false); 
      } catch (error) {
        console.error('Error fetching scheduled courses:', error);
      }
    };

    const fetchStudentName = async () => {
      try {
        const response = await axios.get(`${API_ENDPOINT}/api/current-user/`, {
          headers: {
            'Authorization': `Token ${localStorage.getItem('token')}`
          }
        });
        setStudentName(`${response.data.first_name} ${response.data.last_name}`);
      } catch (error) {
        console.error("Error fetching student name:", error);
      }
    };

    fetchScheduledCourses();
    fetchStudentName();
  }, []);

  const sortCoursesByAcademicTerm = (a, b) => {
    const yearA = parseInt(a.term.split(' ')[1], 10);
    const yearB = parseInt(b.term.split(' ')[1], 10);
    const termA = a.term.split(' ')[0];
    const termB = b.term.split(' ')[0];

    if (yearA !== yearB) {
      return yearA - yearB;
    } else {
      return termOrder[termA] - termOrder[termB];
    }
  };

  const handleDragStart = (event) => {
    setActiveId(event.active.id);
  };

  const handleDragEnd = async (event) => {
    const { active, over } = event;
    setActiveId(null);
  
    if (over) {
      const [activeTerm, activeIndex] = active.id.split('-');
      const [overTerm] = over.id.split('-');
  
      if (fixedColumns.includes(overTerm)) { // Check if the target column is a valid grade column
        if (activeTerm === overTerm) {
          const newItems = arrayMove(courses[activeTerm], parseInt(activeIndex), parseInt(over.id.split('-')[1]));
          setCourses(prev => ({ ...prev, [activeTerm]: newItems }));
        } else {
          const activeCourse = courses[activeTerm][parseInt(activeIndex)];
          const newActiveTermItems = courses[activeTerm].filter((_, index) => index !== parseInt(activeIndex));
          const newOverTermItems = [...(courses[overTerm] || []), activeCourse];
  
          // Temporarily update the state to show the change
          setCourses(prev => ({
            ...prev,
            [activeTerm]: newActiveTermItems,
            [overTerm]: newOverTermItems
          }));
  
          // Update the term in the backend
          try {
            await axios.put(
              `${API_ENDPOINT}/api/courses/${activeCourse.id}/`,
              { term: overTerm },
              { headers }
            );
            setErrorMessage(''); // Clear any previous error messages on success
          } catch (error) {
            console.error('Error updating course term:', error);
            alert((error.response && error.response.data && error.response.data.error) || 'An error occurred while updating the course term.'); // Show an alert message
  
            // Revert the state change if there is an error
            setCourses(prev => ({
              ...prev,
              [activeTerm]: [...newActiveTermItems, activeCourse],
              [overTerm]: newOverTermItems.filter(course => course.id !== activeCourse.id)
            }));
          }
        }
      }
    }
  };  

  const handleExportAsPDF = () => {
    const input = document.getElementById('four-year-plan'); // Reference to the component to be exported
  
    html2canvas(input, { scale: 2 }).then((canvas) => {
      // Create a new canvas with margins
      const margin = 50; // Define the margin size
      const canvasWithMargin = document.createElement('canvas');
      canvasWithMargin.width = canvas.width + margin * 2;
      canvasWithMargin.height = canvas.height + margin * 2;
      const ctx = canvasWithMargin.getContext('2d');
  
      // Fill the new canvas with white background (optional)
      ctx.fillStyle = '#ffffff';
      ctx.fillRect(0, 0, canvasWithMargin.width, canvasWithMargin.height);
  
      // Draw the original canvas onto the new canvas with margins
      ctx.drawImage(canvas, margin, margin);
  
      // Convert the new canvas to a data URL
      const imgData = canvasWithMargin.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
  
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
  
      // Open PDF in a new tab
      const pdfUrl = pdf.output('bloburl');
      window.open(pdfUrl, '_blank');
    });
  };
  
  const headerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  };

  const toggleButtonStyle = {
    display: 'flex',
    border: '1px solid #ccc',
    borderRadius: '5px',
    overflow: 'hidden',
  };

  const toggleOptionStyle = {
    border: 'none',
    padding: '5px 10px',
    cursor: 'pointer',
  };

  const activeToggleOptionStyle = {
    backgroundColor: '#ccc',
  };

  return (
    <div className="page-container" id="four-year-plan">
      <div style={headerStyle}>
        <h1> {studentName} - Four Year Plan</h1>
        <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{...toggleButtonStyle, marginRight: '10px', ...activeToggleOptionStyle}}>
          <button
            style={{ ...toggleOptionStyle}}
            onClick={handleExportAsPDF}
          >
            <FontAwesomeIcon icon={faDownload} />
          </button>
        </div>
        <div style={toggleButtonStyle}>
          <button
            style={{ ...toggleOptionStyle, ...(view === 'grid' ? activeToggleOptionStyle : {})}}
            onClick={() => setView('grid')}
          >
            <FontAwesomeIcon icon={faTh} />
          </button>
          <button
            style={{ ...toggleOptionStyle, ...(view === 'list' ? activeToggleOptionStyle : {}) }}
            onClick={() => setView('list')}
          >
            <FontAwesomeIcon icon={faList} />
          </button>
        </div>
      </div>
      </div>
      <Callout text="The Four Year Plan is a comprehensive blueprint of all the courses you have taken or plan to take during your academic journey. It serves as a strategic roadmap, guiding you through the intricacies of your education, ensuring that you meet all the necessary requirements and milestones along the way." />
      {errorMessage && <div style={{ color: 'red', marginBottom: '20px' }}>{errorMessage}</div>} {/* Display the error message */}
      {isLoading ? (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <img src={loadingGif} alt="Loading..." style={{ width: '100px', height: '100px', marginBottom: '0px' }} />
        </div>
    ) : (
      view === 'list' ? (
        <div style={{ marginTop: '20px' }}>
          {fixedColumns.map((grade) => (
            <div key={grade}>
              <h2>{grade}</h2>
              {courses[grade] && courses[grade].length > 0 ? (
                courses[grade].map(scheduledCourse => (
                  <CourseCard key={scheduledCourse.id} course={scheduledCourse.course} />
                ))
              ) : (
                <p>No courses scheduled for this year.</p>
              )}
            </div>
          ))}
        </div>
      ) : (
        <DndContext 
          collisionDetection={closestCenter} 
          onDragStart={handleDragStart} 
          onDragEnd={handleDragEnd}>
          <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '20px', flexGrow: 1 }}>
            {fixedColumns.map((grade) => (
              <DroppableColumn key={grade} id={grade} term={grade} courses={courses[grade] || []} />
            ))}
          </div>
          <DragOverlay>
            {activeId ? <SortableCourse id={activeId} course={courses[activeId.split('-')[0]][parseInt(activeId.split('-')[1])].course} /> : null}
          </DragOverlay>
        </DndContext>
      ))}
    </div>
  );
};

export default FourYearPlan;