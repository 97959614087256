import React from 'react';
import '../styles/LottieComponent.css'; // Import your CSS file

function LottieComponent() {
  return (
    <div className="lottie-container2"> 
      <div className="lottie-content2"> {/* justifyContent added here */}      
        <lottie-player 
          src="https://lottie.host/608d50d0-8dc2-4110-8e28-cffa8b184187/VlC9uvxxb4.json" 
          background="transparent" 
          speed="1" 
          className="lottie-player2"
          loop 
          autoplay>
        </lottie-player>
        <div className="lottie-text2">
          <h1>Enhancing educational opportunities for high schools.</h1>
          <p>
          EdXcelerate is revolutionizing the education system by making it easy for high schools to offer transferable dual enrollment courses to their students.
          </p>
        </div>
      </div>
    </div>
  );
}

export default LottieComponent;

//With style

// function LottieComponent() {
//   return (
//     <div className="lottie-container"> {/* Assign class names */}
//       <div className="lottie-content">
//       <lottie-player 
//             src="https://lottie.host/608d50d0-8dc2-4110-8e28-cffa8b184187/VlC9uvxxb4.json" 
//             background="transparent" 
//             speed="1" 
//             style={{width: '40%', flexShrink: 0 }} // Adjust width here
//             loop 
//             autoplay>
//         </lottie-player>

//         <div className="lottie-text">
//           <h1>Innovating public education.</h1>
//           <p>
//             EdXcelerate is here to disrupt the traditional education system – making access to transferable dual enrollment courses easy for high school students everywhere.
//           </p>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default LottieComponent;

