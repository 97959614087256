import React from 'react';
import BlogCard from './BlogCard';  // Import the CourseCard component
import '../styles/CardSection.css';  // Import the CSS for this component

function CardSection({ title, courses }) {
    return (
        <div >
        <h1 className="subtitle2">{title}</h1>
        <div className="card-section">            
            <div className="card-list">
                {courses.map(course => <BlogCard key={course.id} course={course} />)}
            </div>
        </div>
        </div>
    );
}

export default CardSection;
