import React from 'react';
import { Form } from 'react-bootstrap';
import '../styles/ContactPage.css';  // Import the CSS for this component
import Button from '../Button';

function ContactPage() {
    return (
        <div className="contact-page">
            <h1 className="subtitle2">Request a Demo</h1>
            <p>We believe that dual enrollment can change the face and pace of public education. If you are interested in using EdXcelerate in your school or dual enrollment in general please contact us below.</p>
            <Form>
                <Form.Group className="mb-3" controlId="formName">
                    <Form.Control type="text" placeholder="Name" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formEmail">
                    <Form.Control type="email" placeholder="Email" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formSchool">
                    <Form.Control type="email" placeholder="School" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formMessage">
                    <Form.Control as="textarea" rows={3} placeholder="Message" />
                </Form.Group>
                <Button>
                    Send
                </Button>
            </Form>
        </div>
    );
}

export default ContactPage;
