import React, { useState } from 'react';
import logo from '../../assets/images/logo3.png';
import animatedLogo from '../../assets/images/animation.gif'; // Replace with your GIF path
import { useNavigate } from 'react-router-dom';
import Button from '../Button';
import styles from '../styles/Navbar.module.css'; // Import the CSS module

function Header2() {
  let navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);

  function scrollToBlog() {
    if (window.location.pathname !== '/') {
      navigate('/');
    }
    setTimeout(() => {
      document.getElementById('blog').scrollIntoView({ behavior: 'smooth' });
    }, 0);
  }

  function scrollToFAQ() {
    if (window.location.pathname !== '/') {
      navigate('/');
    }
    setTimeout(() => {
      document.getElementById('faq').scrollIntoView({ behavior: 'smooth' });
    }, 0);
  }

  function scrollToContact() {
    if (window.location.pathname !== '/') {
      navigate('/');
    }
    setTimeout(() => {
      document.getElementById('contact').scrollIntoView({ behavior: 'smooth' });
    }, 0);
  }
  


  return (
    <header className={styles.header}>
      <a href="/">
        <img
          className={styles.logo}
          src={isHovered ? animatedLogo : logo}
          alt="Logo"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        />
      </a>
      <nav className={styles.nav}>
        <Button variant="tertiary" onClick={scrollToBlog}>Articles</Button>
        <Button variant="tertiary" onClick={scrollToFAQ}>FAQs</Button>
        <Button variant="tertiary" onClick={scrollToContact}>Demo</Button>
        <Button onClick={() => {
          navigate('/log-in');
          window.scrollTo(0, 0);
        }}>Sign In</Button>
      </nav>
    </header>
  );
}

export default Header2;
