import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Form, Button, Container } from 'react-bootstrap';
import '../styles/SignUp.css';
import '../styles/SignUpPage.css';  // Import the CSS for this component

function StudentOnboarding() {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const [schools, setSchools] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState('');
  const navigate = useNavigate();

  // Fetch the list of schools when the component mounts
  useEffect(() => {
    axios.get(`${API_ENDPOINT}/api/schools/`, {
      headers: { 'Authorization': 'Token ' + localStorage.getItem('token') },
    })
    .then(response => {
      const highSchools = response.data.filter(school => school.school_type === 'HS');
      setSchools(highSchools);
    })
    .catch(error => {
      console.error('Error fetching schools', error);
    });
  }, []);

  function handleSelectionChange(event) {
    setSelectedSchool(event.target.value);
  }

  async function handleClick(event) {
    event.preventDefault();
    try {
      const token = localStorage.getItem('token');
      const headers = { 'Authorization': `Token ${token}` };
  
      // Add the user to the selected school      
      await axios.post(`${API_ENDPOINT}/api/schools/${selectedSchool}/join/`, {}, { headers });
  
      navigate('/courses');
    } catch (error) {
      console.error('Error joining school', error);
      alert('Failed to join school. Please try again.');
    }
  }
  
  return (
    <div className="signup-page">
    <Container className="signup-container">
      <h2>Welcome to EdXcelerate!</h2>
      <p>Join your school to start discovering transferable courses.</p>
      <Form onSubmit={handleClick}>
        <Form.Label className="bold-label">What school do you attend?</Form.Label>
        <Form.Select className="mb-3" value={selectedSchool} onChange={handleSelectionChange}>
          <option value="">Select a school</option>
          {schools.map(school => (            
            <option key={school.uuid} value={school.uuid}>{school.name}</option>
          ))}
        </Form.Select>
        <div className="center-text">
          <Button variant="primary" type="submit" className="btn-block custom-button">
            Join School
          </Button>
        </div>
      </Form>
    </Container>
    </div>
  );
}

export default StudentOnboarding;
