import React from 'react';
import { useDroppable } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import SortableCourse from './SortableCourse'; // Ensure the correct path

const DroppableColumn = ({ id, term, courses = [] }) => {
  const { setNodeRef } = useDroppable({
    id,
  });

  const style = {
    backgroundColor: '#EAEAEA',
    padding: '5px',
    margin: '5px',
    borderRadius: '10px',
    minHeight: '200px',
    width: '200px',
    maxWidth: '300px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexGrow: 1,
  };
  const titleStyle = {
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: '20px',
  };

  return (
    <div ref={setNodeRef} style={style}>
      <h4 style={titleStyle}>{term}</h4>
      <SortableContext items={courses.map((_, index) => `${term}-${index}`)} strategy={verticalListSortingStrategy}>
        {courses.map((course, index) => (
          <SortableCourse key={`${term}-${index}`} id={`${term}-${index}`} course={course.course} />
        ))}
      </SortableContext>
    </div>
  );
};

export default DroppableColumn;
