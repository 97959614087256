import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRoad } from '@fortawesome/free-solid-svg-icons';
import { faCircleChevronLeft } from '@fortawesome/free-solid-svg-icons';
import Button from '../Button'; // Assuming you have a reusable Button component
import RequirementSection from './RequirementSection2';
import InputOverlay from '../InputOverlay'; // Adjust the path as necessary
import axios from 'axios';
import loadingGif from '../../assets/images/animation.gif';


const PathwayPage = () => {
  const [isLoading, setIsLoading] = useState(true); 

  const { id } = useParams();
  const navigate = useNavigate();
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);

  const [editPathwayName, setEditPathwayName] = useState('');
  const [editPathwayDescription, setEditPathwayDescription] = useState('');
  const [editPathwayColor, setEditPathwayColor] = useState('');
  const [requirements, setRequirements] = useState([]);

  const [newRequirementName, setNewRequirementName] = useState('');
  const [newRequirementDescription, setNewRequirementDescription] = useState('');
  const [newRequirementUnits, setNewRequirementUnits] = useState(0);
  const [newRequirementCourses, setNewRequirementCourses] = useState(0);

  const [reloadKey, setReloadKey] = useState(0); // Initial key


  
  const [pathway, setPathway] = useState(null); // State to store the fetched pathway data
  const [ownsPathway, setOwnsPathway] = useState(null);
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const token = localStorage.getItem('token'); // Assuming you store the token in localStorage

  const handleEditRequirement = async (requirementId, updatedRequirementData) => {
    const headers = {
      Authorization: `Token ${token}`,
    };
    try {
      const response = await axios.put(`${API_ENDPOINT}/api/requirements/${requirementId}/`, updatedRequirementData, { headers });
      // Update the local state with the new requirements
      const updatedRequirements = requirements.map(req =>
        req.uuid === requirementId ? { ...req, ...response.data } : req
      );
      setRequirements(updatedRequirements);
    } catch (error) {
      console.error('Error updating requirement:', error);
    }
  };
    
  const handleEditCourseFulfillment = async (requirementId, courseId, payload) => {
    const headers = {
      Authorization: `Token ${token}`,
    };

    try {
      const response = await axios.post(`${API_ENDPOINT}/api/courses/${courseId}/requirement/`, payload, { headers });
      return response;
    } catch (error) {
      console.error('Error updating requirement:', error);
    }
  };
  
  useEffect(() => {
    const fetchPathway = async () => {
      try {
        const headers = {
          Authorization: `Token ${token}`, // Include the token in the request headers
        };
        const user = await axios.get(`${API_ENDPOINT}/api/current-user/`, { headers });
        const response = await axios.get(`${API_ENDPOINT}/api/pathways/${id}/`, { headers });
	const fetchedRequirements = response.data.requirements;
        const sortedRequirements = [...fetchedRequirements].sort((a, b) => a.name.localeCompare(b.name));
	      setPathway(response.data); // Update the pathway state with the fetched data
        setEditPathwayName(response.data.name);
        setEditPathwayDescription(response.data.description);
        setEditPathwayColor(response.data.color);
        setRequirements(sortedRequirements);
        const ownsPathway = user.data.school.uuid === response.data.school_info.uuid;
        setOwnsPathway(ownsPathway);
        setIsLoading(false); 
      } catch (error) {
        console.error('Error fetching pathway:', error);
        // Handle error, e.g., navigate to an error page or show an error message
      }
    };

    if (id) {
      fetchPathway();
    }
  }, [id, token]); 

  const handleEditPathwaySubmit = async (e) => {
    e.preventDefault();  

    // Use state variables for submission
    const updatedPathwayData = {
      name: editPathwayName,
      description: editPathwayDescription,
      color: editPathwayColor,
      requirements: requirements,
      // Other fields
    };


    const headers = {
      Authorization: `Token ${token}`, // Include the authentication token
    };
  
    try {
      // Send a POST request to the update pathway endpoint
      const response = await axios.post(`${API_ENDPOINT}/api/pathways/${id}/`, updatedPathwayData, { headers });
      
      setPathway(response.data); // Update the pathway state with the response data
      toggleOverlay(); // Close the edit form overlay
  
      // Optionally, you might want to show a success message or perform other actions upon successful update
    } catch (error) {
      console.error('Error updating pathway:', error);
      // Handle error, e.g., showing an error message to the user
    }
  
  };


  const handleAddRequirement = async (e) => {
    e.preventDefault();

    const headers = {
      Authorization: `Token ${token}`, // Include the authentication token
    };

      try {
        const response = await axios.post(`${API_ENDPOINT}/api/add-requirement-to-pathway/`, {
          pathway_uuid: id,
          name: newRequirementName,
          description: newRequirementDescription,
          number_of_courses: newRequirementCourses,
          number_of_units: newRequirementUnits
        }, { headers });
    
        window.location.reload();

        // Close the overlay
        toggleOverlay2();


    } catch (error) {
        console.error('Error:', error);
    }

};



  const toggleOverlay = () => {
    setIsOverlayVisible(!isOverlayVisible);
};

const [isOverlayVisible2, setIsOverlayVisible2] = useState(false);
  
const toggleOverlay2 = () => {
  setIsOverlayVisible2(!isOverlayVisible2);
};

const deletePathway = async () => {
  const confirmDelete = window.confirm("Are you sure you want to delete this pathway?");
  if (!confirmDelete) {
    return; // User canceled the operation
  }

  try {
    navigate('/pathways'); // Assuming you have a route to list pathways
    const headers = {
      Authorization: `Token ${token}`,
    };
    await axios.delete(`${API_ENDPOINT}/api/pathways/${id}/`, { headers });
    
    // You might also show a success message here
  } catch (error) {
    console.error('Error deleting pathway:', error);
    // Handle the error, possibly showing an error message to the user
  }
};



  const pathwayForm = (
    <form>
        <div className="mb-3">
            <label style={{ fontWeight: 'bold' }} htmlFor="pathwayName" className="form-label">Pathway Name</label>
            <input type="text" className="form-control" id="pathwayName" name="pathwayName" value={editPathwayName} onChange={(e) => setEditPathwayName(e.target.value)}/>
        </div>

        <div className="mb-3">
            <label style={{ fontWeight: 'bold' }} htmlFor="pathwayDescription" className="form-label">Pathway Description</label>
            <textarea className="form-control" id="pathwayDescription" name="pathwayDescription" rows="3" value={editPathwayDescription} onChange={(e) => setEditPathwayDescription(e.target.value)} ></textarea>
        </div>

        <div className="mb-3">
            <label style={{ fontWeight: 'bold' }} htmlFor="pathwayColor" className="form-label" >Pathway Color</label>
            <select className="form-select" id="pathwayColor" name="pathwayColor" value={editPathwayColor} onChange={(e) => setEditPathwayColor(e.target.value)} >
            <option value="null"></option>
                <option value="red">Red</option>
                <option value="blue">Blue</option>
                <option value="green">Green</option>
                <option value="yellow">Yellow</option>
                <option value="purple">Purple</option>
                <option value="orange">Orange</option>
                <option value="pink">Pink</option>
            </select>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <Button type="submit" onClick={handleEditPathwaySubmit} className="btn btn-primary">Save Pathway</Button>
          <Button onClick={deletePathway} className="btn btn-primary" style={{ backgroundColor: 'red', color: 'white' }}>Delete Pathway</Button>

        </div>
    </form>
);

const requirementForm = (
  <form onSubmit={handleAddRequirement}>
      <div className="mb-3">
          <label style={{ fontWeight: 'bold' }} htmlFor="requirementName" className="form-label">Requirement Name</label>
          <input type="text" className="form-control" id="requirementName" name="requirementName" value={newRequirementName} onChange={(e) => setNewRequirementName(e.target.value)} />
      </div>

      <div className="mb-3">
          <label style={{ fontWeight: 'bold' }} htmlFor="requirementDescription" className="form-label">Requirement Description</label>
          <textarea className="form-control" id="requirementDescription" name="requirementDescription" rows="3" value={newRequirementDescription} onChange={(e) => setNewRequirementDescription(e.target.value)}></textarea>
      </div>

      <div className="mb-3">
          <label style={{ fontWeight: 'bold' }} htmlFor="units" className="form-label">Number of Units Required</label>
          <input type="number" className="form-control" id="units" name="units" value={newRequirementUnits} onChange={(e) => setNewRequirementUnits(e.target.value)}/>
      </div>
      <div className="mb-3">
          <label style={{ fontWeight: 'bold' }} htmlFor="courses" className="form-label">Number of Courses Required</label>
          <input type="number" className="form-control" id="courses" name="courses" value={newRequirementCourses} onChange={(e) => setNewRequirementCourses(e.target.value)}/>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <Button type="submit" className="btn btn-primary">Add Requirement</Button>
      </div>
  </form>
);

  

  const [isHovered, setIsHovered] = React.useState(false);

  const styles = {
    container: {
      width: '100%',
    //   marginBottom: '20px',
      backgroundColor: '#FFFFFF',
      display: 'flex',
      flexDirection: 'row', // Ensuring the children are laid out in a row
      alignItems: 'flex-start',
    },
    content: {
      width: '100%',
      marginLeft: '10px',
      display: 'flex',
      flexDirection: 'column', // Laying out the content in a column
      alignItems: 'flex-start', // Aligning content to the start
      marginBottom: '100px',

    },
    h3: {
      marginTop: '0',      
    },
    schoolLink: {
      display: 'inline-block',
      margin: '10px 0',
      fontWeight: '600',
      color: '#00008A', // Night Sky Blue
      textDecoration: 'none',
    },
    requirement: {
      padding: '5px 10px',
      borderRadius: '15px',
      color: '#FFFFFF',
    },
    icon: {
      fontSize: '36px',
      alignSelf: 'flex-start',
      color: pathway ? pathway.color : 'Blue',
    },
    description: {
        textAlign: 'left', // Explicitly setting text alignment to left
      },

    topBar: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '50px',
    marginTop: '50px',
    },
    
    buttonsContainer: {
    display: 'flex',
    gap: '20px', // Add space between buttons
    },

    backButton: {
    fontSize: '40px',
    marginRight: '20px',
    cursor: 'pointer',
    },

    backButtonContainer: {
        cursor: 'pointer',
        transition: 'color 0.3s', // Smooth transition for color
        color: isHovered ? '#00008A' : 'black', // Night Sky Blue color on hover
      },

    iconContainer: {
        position: 'relative',
        display: 'inline-block',
        borderRadius: '50%',
        padding: '10px',
        backgroundColor: isHovered ? '#E6EFFF' : '#FFF', // Lighter blue background on hover
        color: isHovered ? '#00008A' : 'black', // Night Sky Blue color on hover
        transition: 'background-color 0.3s, color 0.3s', // Smooth transition for background-color and color
    },
    requirementsContainer: {
        display: 'flex',
        flexDirection: 'column', // Set the direction to column
        gap: '20px', // Set the gap between requirements
        marginTop: '10px',
        width: '100%',
      },
    };

  return (
    <div className="page-container">
      {isOverlayVisible && (
        <InputOverlay
            title="Edit Pathway"
            description="Please edit the details of the pathway below."
            onClose={toggleOverlay}
            content={pathwayForm}
        />
      )}
      {isOverlayVisible2 && (
        <InputOverlay
            title="Add Requirement"
            description="Please edit the details of the requirement below."
            onClose={toggleOverlay2}
            content={requirementForm}
        />
      )}
      
    {pathway && (      
    <div style={ styles.container }>        
      <div style={styles.content}>        
        <h1 style={styles.h3}>{pathway.name}</h1>
        <h3 style={styles.h3}>{pathway.school}</h3>
        <p style={styles.description}>{pathway.description}</p>
        {pathway && ownsPathway && (
          <div style={styles.buttonsContainer}>
            <Button onClick={toggleOverlay}>Edit Pathway</Button>
            <Button onClick={toggleOverlay2}>Add Requirement</Button>
          </div>
        )}
        
        <h2>Requirements</h2>
        {isLoading ? (
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
              <img src={loadingGif} alt="Loading..." style={{ width: '100px', height: '100px', marginBottom: '0px' }} />
            </div>
          ) : (
            <div key={reloadKey} style={styles.requirementsContainer}>
              {requirements.map((req) => (
                <RequirementSection
                  key={req.uuid}
                  requirements={requirements}
                  requirement={req}
                  color={pathway.color}
                  onEditRequirement={handleEditRequirement}
                  onEditCourseFulfillment={handleEditCourseFulfillment}
                  ownsPathway={ownsPathway}
                />
              ))}
        </div>

      )}
      </div>
    </div>
    )}
    </div>
  );
}

export default PathwayPage;
  

  
