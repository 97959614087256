import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './components/styles/global.css';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from  './components/public/Navbar'
import Navbar2 from  './components/admin/Navbar2'
import HSNavbar2 from './components/admin/HSNavbar2';
import StudentNavbar from  './components/student/Navbar'
import AdminStudentNavbar from './components/admin/AdminStudentNavbar';
import HomePage from './components/public/HomePage'; 
import SignUp from './components/public/SignUp';
import LogIn from './components/public/Login2';
import StudentOnboarding from './components/student/StudentOnboarding';
import AdminOnboarding from './components/admin/AdminOnboarding';
import CoursePage from './components/admin/CoursePage2';
import StudentCoursePage from './components/student/CoursePage2';
import Profile from './components/admin/Profile';
import BlogPost from './components/public/BlogPost';
import PathwaysTab from './components/admin/PathwaysTab';
import StudentPathwaysTab from './components/student/PathwaysTab';
import CoursesTab from './components/admin/CoursesTab';
import PartnerCoursesTab from './components/admin/PartnerCoursesTab';
import StudentCoursesTab from './components/student/CoursesTab';
import PathwayPage from './components/admin/PathwayPage';
import StudentPathwayPage from './components/student/PathwayPage';
import FourYearPlan from './components/student/FourYearPlan';
import StudentsList from './components/admin/StudentsList';
import Test from './components/Test'

function App() {

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userType, setUserType] = useState(null);
  const [schoolType, setSchoolType] = useState(null);
  const [isAdminViewingAsStudent, setIsAdminViewingAsStudent] = useState(localStorage.getItem('isAdminViewingAsStudent') === 'true'); // New state


  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const token = localStorage.getItem('token');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    
    
    const fetchCurrentUser = async () => {
      try {
        const headers = {};
        if (token) {
          headers['Authorization'] = `Token ${token}`;
        }
        const response = await axios.get(`${API_ENDPOINT}/api/current-user/`, { headers });
        console.log(response.data.school.school_type)
        setIsAuthenticated(true);
        setUserType(response.data.user_type);
        setSchoolType(response.data.school.school_type);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching current user:", error);
        setIsLoading(false);
      }
    };

    if (token) {
      fetchCurrentUser();
    } else {
      setIsLoading(false);
    }
  }, [API_ENDPOINT, token]);



  if (isLoading) {
    return null;
  }

  const renderRootRoute = () => {
    if (!isAuthenticated) {
      return <HomePage />;
    } else if (userType === 2 && !isAdminViewingAsStudent) { // Admin view
      return <CoursesTab />;
    } else {
      return <FourYearPlan />; // Student view
    }
  };


  return (
    <div className="App">
      <Router>
      {!isAuthenticated ? <Navbar /> :
          (userType === 2 && schoolType === 'HS' && !isAdminViewingAsStudent) ? <HSNavbar2 /> :
          (userType === 2 && !isAdminViewingAsStudent) ? <Navbar2 /> :
          isAdminViewingAsStudent ? <AdminStudentNavbar/> : // Special navbar
          <StudentNavbar />}

        <Routes>  
        <Route path="/" element={renderRootRoute()} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/log-in" element={<LogIn />} />
          <Route path="/student-onboarding" element={<StudentOnboarding />} />
          <Route path="/admin-onboarding" element={<AdminOnboarding />} />
          <Route path="/course-page/:id" element={userType === 2 ? <CoursePage /> : <StudentCoursePage />} />
          <Route path="/blog-page/:id" element={<BlogPost />} />                    
          <Route path="/pathways/:id" element={userType === 2 ? <PathwayPage /> : <StudentPathwayPage />}  />
          <Route path="/pathways" element={userType === 2 ? <PathwaysTab /> : <StudentPathwaysTab />}  />
          <Route path="/courses" element={userType === 2 ? <CoursesTab /> : <StudentCoursesTab />} />
          <Route path="/partner-courses" element={<PartnerCoursesTab />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/plan" element={<FourYearPlan />} />
          <Route path="/students-list" element={<StudentsList />} />
          <Route path="/test" element={<Test />} />          
        </Routes>
      </Router>
    </div> 
  );  
}

export default App;
