import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripVertical } from '@fortawesome/free-solid-svg-icons';

const SortableCourse = ({ id, course }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id,
  });

  const navigate = useNavigate();

  const handleClick = () => {
    if (!isDragging) {
      navigate(`/course-page/${course.uuid}`);
    }
  };

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    padding: '10px',
    margin: '5px',
    backgroundColor: isDragging ? '#EAEAEA' : 'white',
    border: '4px solid #FFE27B',
    borderRadius: '10px',
    textAlign: 'center',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    cursor: isDragging ? 'grabbing' : 'pointer',
    width: '150px',
  };

  const iconStyle = {
    marginRight: '3px',
    cursor: 'grab',
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes}>
      <div {...listeners} style={iconStyle}>
        <FontAwesomeIcon icon={faGripVertical} />
      </div>
      <div onClick={handleClick} style={{ flexGrow: 1 }}>
        {course.course_id}
      </div>
    </div>
  );
};

export default SortableCourse;
