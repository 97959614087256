import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRoad } from '@fortawesome/free-solid-svg-icons';
import { faCircleChevronLeft } from '@fortawesome/free-solid-svg-icons';
import Button from '../Button'; // Assuming you have a reusable Button component
import RequirementSection from './RequirementSection2';
import InputOverlay from '../InputOverlay'; // Adjust the path as necessary
import axios from 'axios';


const CoursePage = () => {
  const [isLoading, setIsLoading] = useState(true); 

  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  
  const { id } = useParams(); // Use the correct param name as used in your route
  const navigate = useNavigate();
  const [course, setCourse] = useState(null); // State to store the fetched course data
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const token = localStorage.getItem('token'); // Assuming you store the token in localStorage
  
  const [userSchool, setUserSchool] = useState(null);
  const [isExternalCourse, setIsExternalCourse] = useState(false);


  const [courseId, setCourseId] = useState('');
  const [courseTitle, setCourseTitle] = useState('');
  const [courseDescription, setCourseDescription] = useState('');
  const [courseUnits, setCourseUnits] = useState('');
  const [courseSubject, setCourseSubject] = useState('');

  const [unitsTransferred, setUnitsTransferred] = useState('');
  const [isTransferable, setIsTransferable] = useState(false);

  

  useEffect(() => {
    const headers = {
      Authorization: `Token ${token}`,
    };
  
    const fetchCurrentUserAndCourse = async () => {
      try {
        const userResponse = await axios.get(`${API_ENDPOINT}/api/current-user/`, { headers });
        setUserSchool(userResponse.data.school);
        console.log(userResponse.data.school);
        
        const response = await axios.get(`${API_ENDPOINT}/api/get-course/${id}/`, { headers });
        const courseData = response.data;
        setCourse(courseData);
  
        // Initialize form fields with fetched course data
        setCourseId(courseData.course_id);
        setCourseTitle(courseData.title);
        setCourseDescription(courseData.description);
        setCourseUnits(courseData.units);
        setCourseSubject(courseData.subject);
  
        // Compare with the just fetched user's school
        const isExternal = courseData.school.uuid !== userResponse.data.school.uuid;
        setIsExternalCourse(isExternal);
        
        // If the course is external, check its transferability
        if (isExternal) {
          try {
            const transferabilityResponse = await axios.get(`${API_ENDPOINT}/api/check-transferability/${id}/${userResponse.data.school.uuid}`, {
              headers: { Authorization: `Token ${token}` },
            });
            setIsTransferable(transferabilityResponse.data.isTransferable);
          } catch (error) {
            console.error("Error checking transferability:", error);
          }
        }
        
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching current user or course:", error);
        setIsLoading(false);
      }
    };
  
    if (token && id) {
      fetchCurrentUserAndCourse();
    }
  }, [id, token]); // Dependency array
  

  const handleRemoveTransferability = async () => {
    const confirmRemove = window.confirm("Are you sure you want to remove transferability?");
    if (!confirmRemove) return;
  
    try {
      await axios.delete(`${API_ENDPOINT}/api/remove-transferability/`, {
        data: { original_course_id: id, accepting_school_id: userSchool.uuid }, // Adjust as necessary
        headers: { Authorization: `Token ${token}` },
      });
      setIsTransferable(false); // Update state to reflect change
      alert("Transferability removed successfully.");
    } catch (error) {
      console.error("Error removing transferability:", error);
      alert("Failed to remove transferability.");
    }
  };
    
  const toggleOverlay = () => {
      setIsOverlayVisible(!isOverlayVisible);
  };

  const [isOverlayVisible2, setIsOverlayVisible2] = useState(false);
    
  const toggleOverlay2 = () => {
    setIsOverlayVisible2(!isOverlayVisible2);
  };

  const deleteCourse = async () => {
    const confirmDelete = window.confirm("Are you sure you want to delete this course?");
    if (!confirmDelete) {
      return; // Stop the function if the user cancels
    }
  
    try {
      const headers = {
        Authorization: `Token ${token}`, // Use your existing token
        'Content-Type': 'application/json',
      };
      await axios.delete(`${API_ENDPOINT}/api/courses/${id}/`, { headers });
      // Show a success message if needed
      setIsOverlayVisible(false);
      navigate('/courses');
    } catch (error) {
      console.error('Error deleting course:', error);
      // Handle error (e.g., show an error message)
    }
  };

  const saveCourseDetails = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
  
    const courseData = {
      course_id: courseId, // Assuming your API expects a course_id field
      title: courseTitle,
      description: courseDescription,
      units: courseUnits,
      subject: courseSubject
      // Add other fields as necessary
    };
  
    const headers = {
      Authorization: `Token ${token}`, // Use your existing token
      'Content-Type': 'application/json',
    };
  
    try {
      const response = await axios.post(`${API_ENDPOINT}/api/courses/${id}/`, courseData, { headers });

      const updatedCourse = response.data;

      setCourse({
        ...course,
        course_id: updatedCourse.course_id || courseId,
        title: updatedCourse.title || courseTitle,
        description: updatedCourse.description || courseDescription,
        units: updatedCourse.units || courseUnits,
        subject: updatedCourse.subject || courseSubject
        // Map any other fields you need to update
      });

      setIsOverlayVisible(false);
      // Handle success (e.g., show a success message or navigate away)
    } catch (error) {
      console.error('Error saving course:', error);
      // Handle error (e.g., show an error message)
    }
  };

  

  const courseForm = (
    <form onSubmit={saveCourseDetails}>
        <div className="mb-3">
            <label style={{ fontWeight: 'bold' }} htmlFor="pathwayName" className="form-label">Course ID</label>
            <input 
              type="text"
              className="form-control"
              id="courseId"
              name="courseId"
              value={courseId}
              onChange={(e) => setCourseId(e.target.value)}
            />
        </div>

        <div className="mb-3">
            <label style={{ fontWeight: 'bold' }} htmlFor="pathwayName" className="form-label">Course Title</label>
            <input
              type="text"
              className="form-control"
              id="courseTitle"
              value={courseTitle}
              onChange={(e) => setCourseTitle(e.target.value)}
            />
        </div>


        <div className="mb-3">
            <label style={{ fontWeight: 'bold' }} htmlFor="pathwayDescription" className="form-label">Course Description</label>
            <textarea
              className="form-control"
              id="courseDescription"
              rows="3"
              value={courseDescription}
              onChange={(e) => setCourseDescription(e.target.value)}
            />
        </div>

        <div className="mb-3">
          <label htmlFor="courseUnits" className="form-label" style={{ fontWeight: 'bold' }}>Units</label>
          <input
            type="number"
            className="form-control"
            id="courseUnits"
            value={courseUnits}
            onChange={(e) => setCourseUnits(e.target.value)}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="subject" className="form-label" style={{ fontWeight: 'bold' }}>Subject</label>
          <select
            className="form-control"
            id="subject"
            name="subject"
            value={courseSubject}
            onChange={(e) => setCourseSubject(e.target.value)}
          >
            <option value="">Select a Subject</option>
            <option value="Mathematics">Mathematics</option>
            <option value="English">English</option>
            <option value="History">History</option>
            <option value="Science">Science</option>
            <option value="Art">Art</option>
            <option value="Music">Music</option>
            <option value="Computer Science">Computer Science</option>
            <option value="Physical Education">Physical Education</option>
            <option value="Language">Language</option>
            <option value="Economics">Economics</option>
            <option value="Other">Other</option>
            {/* Add more subjects as needed */}
          </select>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <Button type="submit" className="btn btn-primary">Save Course Details</Button>
        <Button onClick={deleteCourse} style={{ backgroundColor: 'red', color: 'white' }}>Delete Course</Button>
        </div>
    </form>
);

const statusForm = (
  <form>
      <div className="mb-3">
          <label style={{ fontWeight: 'bold' }} htmlFor="requirementName" className="form-label">Requirement Name</label>
          <input type="text" className="form-control" id="requirementName" name="requirementName" />
      </div>

      <div className="mb-3">
          <label style={{ fontWeight: 'bold' }} htmlFor="requirementDescription" className="form-label">Requirement Description</label>
          <textarea className="form-control" id="requirementDescription" name="requirementDescription" rows="3"></textarea>
      </div>

      <div className="mb-3">
          <label style={{ fontWeight: 'bold' }} htmlFor="units" className="form-label">Number of Units Required</label>
          <input type="number" className="form-control" id="units" name="units" />
      </div>
      <div className="mb-3">
          <label style={{ fontWeight: 'bold' }} htmlFor="courses" className="form-label">Number of Courses Required</label>
          <input type="number" className="form-control" id="courses" name="courses" />
      </div>

      <Button type="submit" className="btn btn-primary">Save Requirement</Button>
  </form>
);

const handleTransferabilitySubmit = async (e) => {
  e.preventDefault();

  const transferData = {
    original_course_id: id, // Assuming 'id' is the ID of the course to make transferable
    accepting_school_id: userSchool.uuid, // The accepting school's ID
    adjusted_units: unitsTransferred
  };

  try {
    const response = await axios.post(`${API_ENDPOINT}/api/transfer-course/`, transferData, {
      headers: {
        Authorization: `Token ${token}`,
        'Content-Type': 'application/json',
      },
    });

    setIsOverlayVisible2(false); // Close the overlay
    // Optionally, handle success, e.g., displaying a message or updating state
  } catch (error) {
    console.error('Error submitting transferability:', error);
    // Optionally, handle errors, e.g., displaying an error message
  }
};


const transferabilityForm = (
  <form onSubmit={handleTransferabilitySubmit}>
      <div className="mb-3">
          <label htmlFor="unitsTransferred" className="form-label" style={{ fontWeight: 'bold' }}>Unit Value</label>
          <input 
            type="number" 
            className="form-control" 
            id="unitsTransferred" 
            value={unitsTransferred}
            onChange={(e) => setUnitsTransferred(e.target.value)} 
          />
      </div>

      <Button type="submit" className="btn btn-primary">Accept Transferability</Button>
  </form>
);


  

  const [isHovered, setIsHovered] = React.useState(false);

  const styles = {
    container: {
      width: '100%',
    //   marginBottom: '20px',
      backgroundColor: '#FFFFFF',
      display: 'flex',
      flexDirection: 'row', // Ensuring the children are laid out in a row
      alignItems: 'flex-start',
    },
    content: {
      marginLeft: '10px',
      display: 'flex',
      flexDirection: 'column', // Laying out the content in a column
      alignItems: 'flex-start', // Aligning content to the start
      marginBottom: '100px',

    },
    h3: {
      marginTop: '0',      
    },
    schoolLink: {
      display: 'inline-block',
      margin: '10px 0',
      fontWeight: '600',
      color: '#00008A', // Night Sky Blue
      textDecoration: 'none',
    },
    requirement: {
      padding: '5px 10px',
      borderRadius: '15px',
      color: '#FFFFFF',
    },
    icon: {
      fontSize: '36px',
      alignSelf: 'flex-start',
    },
    description: {
        textAlign: 'left', // Explicitly setting text alignment to left
      },

    topBar: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '50px',
    marginTop: '50px',
    },
    buttonsContainer: {
    display: 'flex',
    gap: '20px', // Add space between buttons
    },

    backButton: {
    fontSize: '40px',
    marginRight: '20px',
    cursor: 'pointer',
    },

    backButtonContainer: {
        cursor: 'pointer',
        transition: 'color 0.3s', // Smooth transition for color
        color: isHovered ? '#00008A' : 'black', // Night Sky Blue color on hover
      },

    iconContainer: {
        position: 'relative',
        display: 'inline-block',
        borderRadius: '50%',
        padding: '10px',
        backgroundColor: isHovered ? '#E6EFFF' : '#FFF', // Lighter blue background on hover
        color: isHovered ? '#00008A' : 'black', // Night Sky Blue color on hover
        transition: 'background-color 0.3s, color 0.3s', // Smooth transition for background-color and color
    },
    requirementsContainer: {
        display: 'flex',
        flexDirection: 'column', // Set the direction to column
        gap: '20px', // Set the gap between requirements
        marginTop: '10px',
        width: '100%',
      },
      requirementsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '10px',
        marginTop: '10px',
        marginBottom: '10px', // Added bottom margin
      },
      requirement: {
          padding: '0px 10px',
          borderRadius: '5px',
          color: '#FFFFFF',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        modeTag: {
          backgroundColor: '#e0e0e0',
          color: '#333',
          padding: '2px 8px',
          borderRadius: '4px',
          display: 'inline-block',
          fontSize: '0.8em',
        },
        requirement: {
          padding: '0px 10px',
          borderRadius: '5px',
          color: '#000',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#FFE27B', // This color is for prerequisites in your CourseCard
        },
        strong: {
          display: 'block',
          margin: '5px 0',
        },
        requirementsContainer: {
          display: 'flex',
          flexWrap: 'wrap',
          gap: '10px',
          marginTop: '10px',
          marginBottom: '10px',
        },
        schoolAndModeContainer: {
          display: 'flex',
          alignItems: 'center',
          gap: '10px', // Adjust the space between the school name and the mode tag as needed
        },
    };


  if (isLoading) {
    return <div>Loading...</div>; // Show a loading message or a spinner
  }

  const processRequirements = () => {
    let displayReqs = [];
  
    // Updated structure to hold both names and colors
    const uniqueReqs = new Map();
  
    // Handle non-duplicable groups
    course.non_duplicables.forEach((nonDupGroup) => {
      const groupNames = nonDupGroup.requirements.map(req => req.name).join(' OR ');
      const groupColors = nonDupGroup.requirements.map(req => req.pathway_colors ? req.pathway_colors[0] : 'defaultColor');
      // Assuming the same color for all requirements in a group, or picking the first one's color
      const color = groupColors[0]; // This can be adjusted based on how you want to handle colors for grouped requirements
      // Add to the uniqueReqs map to ensure no duplicates, storing both name and color
      nonDupGroup.requirements.forEach(req => uniqueReqs.set(req.uuid, { name: groupNames, color: color, pathway: req.pathway_names[0] }));
    });
  
    // Add individual requirements if they are not part of any non-duplicable group
    course.requirements.forEach(req => {
      if (!uniqueReqs.has(req.uuid)) {
        const color = req.pathway_colors && req.pathway_colors[0] ? req.pathway_colors[0] : 'defaultColor';
        const pathwayName = req.pathway_names[0];
        uniqueReqs.set(req.uuid, { name: req.name, color: color, pathway: pathwayName });
      }
    });
  
    // Convert the unique requirements map to an array for rendering, preserving both name and color
    uniqueReqs.forEach((value, key) => {
      if (!displayReqs.find(req => req.name === value.name)) { // Ensure no duplicate groups are added
        displayReqs.push(value);
      }
    });
  
    return displayReqs;
  };

  return (
    <div className="page-container">
      {isOverlayVisible && (
        <InputOverlay
            title="Edit Course"
            description="Please edit the details of the course below."
            onClose={toggleOverlay}
            content={courseForm}
        />
      )}
      {isOverlayVisible2 && (
        <InputOverlay
          title="Accept Transferability"
          description="Please enter how many units you would like this course to be worth in your school. Once you accept transferablility, your students will be able to see this course as available for them."
          onClose={toggleOverlay2}
          content={transferabilityForm}
        />
      )}

    <div style={ styles.container }>        
      <div style={styles.content}>        
        <h1 style={styles.h3}>{course.course_id + ": " + course.title}</h1>
        <div style={styles.schoolAndModeContainer}>
          <h3 style={styles.strong}>{course.school.name}</h3>        
          {course.mode && (
            <span style={styles.modeTag}>{course.mode}</span>
          )}
        </div>
        <p style={styles.description}>{course.description}</p>

        <div>        
        {/* Conditionally render prerequisites if they exist */}
        {course.prerequisites && course.prerequisites.length > 0 && (
          <>
            <strong style={styles.strong}>Prerequisites</strong>
            <div style={styles.requirementsContainer}>
              {course.prerequisites.map((prerequisite, index) => (
                <div key={index} style={styles.requirement}>
                  {`${prerequisite.course_id}: ${prerequisite.title}`}
                </div>
              ))}
            </div>
          </>
        )}
      </div>

        <strong>Requirements</strong>
        <div style={styles.requirementsContainer}>
          {processRequirements().map((req, index) => {
            return (
              <div 
                key={index} 
                style={{
                  ...styles.requirement, 
                  backgroundColor: '#FFE27B'
                }}
              >
                {`${req.pathway}: ${req.name}`}
              </div>
            );
          })}
        </div>
        <div style={styles.buttonsContainer}>
          {!isExternalCourse ? (
            <Button onClick={toggleOverlay}>Edit Course Details</Button>
          ) : isTransferable ? (
            <Button onClick={handleRemoveTransferability}>Remove Transferability</Button>
          ) : (
            <Button onClick={toggleOverlay2}>Accept Transferability</Button>
          )}
        </div>
      </div>
    </div>
    
    </div>
  );
}

export default CoursePage;
  
