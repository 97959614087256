import React from 'react';
import '../styles/Home-Header.css';
import Button from '../Button';
import { useNavigate } from 'react-router-dom';



function Header() {

  const navigate = useNavigate();
  return (
    <header className="main-header-homeheader">
      <h1 className='title-homeheader'>Transform Education with EdXcelerate</h1>
      <h2 className='subtitle-homeheader'>Empower your students by integrating dual enrollment in your school.</h2>
      <div className="button-container-homeheader">
        <Button onClick={() => {
          navigate('/sign-up');
          window.scrollTo(0, 0);
        }}>Sign Up</Button>
        <Button variant="secondary" onClick={scrollToLottie}>Learn More</Button>
      </div>
    </header>
  );
}

function scrollToLottie() {
    document.getElementById('lottie-component').scrollIntoView({ behavior: 'smooth' });
}

export default Header;
